@use "../../../scss/mixins" as mix;
@use "../../../scss/variables" as var;

.Project {
  width: 1210px;
  position: relative;
  transition: 0.6s;

  div {
    @include mix.flexCenter(row);

    img {
      position: relative;
      border-radius: 2px;
      width: 670px;
      border-radius: 6px;
      height: auto;
      cursor: pointer;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      transition: 0.4s ease-in;
    }

    .Project-info-container {
      @include mix.flexStart(column);
      align-items: flex-start;
      padding: 20px;

      h4 {
        font-size: 26px;
      }

      p {
        font-size: 16px;
        padding: 10px 0;
        z-index: 2;
      }
    }

    .technologies-container {
      span {
        padding: 3px 0;
        color: var.$primary-colour;
      }
    }

    .links-container {
      margin-top: 10px;

      button {
        cursor: pointer;
        padding: 8px;
        background: none;
        outline: none;
        border: none;
        font-weight: 600;
        font-size: 13px;
        @include mix.btn-border-drawing(
          var.$secondary-colour,
          orange,
          2px,
          bottom,
          right
        );
      }
    }
  }
  .image-hover-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 2px solid white;
  }

  @media (max-width: 1200px) {
    margin: 55px 0;

    .image-liveLink {
      display: none;
    }

    .Project-info-container {
      width: 90vw;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.845);
      background-blend-mode: darken;
      border-radius: 6px;

      h4 {
        align-self: flex-start !important;
      }

      p {
        text-align: start !important;
        position: relative;
        z-index: 121232232300;
        font-size: 144px;
      }

      .technologies-container {
        align-self: flex-start !important;
        display: flex;
        flex-wrap: wrap;

        span {
          margin-left: 0 !important;
          margin-right: 16px !important;
        }
      }

      .links-container {
        align-self: flex-start !important;
      }
    }
  }
  hr {
    margin: 110px 0;
    width: 150vw;
    margin-left: -50vw;
  }
}
