@use "../../scss/variables" as var;
@use "../../scss/mixins" as mix;

.Contact {
  width: 100vw;
  position: relative;
  background: none;
  padding: 200px 300px 100px 300px;
  display: flex;
  scroll-margin: -220px;
  z-index: 0;

  @media (max-width: 1900px) {
    padding: 200px 200px 100px 200px;
  }

  @media (max-width: 1600px) {
    padding: 200px 100px 100px 100px;
  }

  @media (max-width: 1300px) {
    padding: 200px 20px 100px 20px;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    padding-bottom: 0;
  }

  .Contact::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-size: 40px;
    background-image: radial-gradient(
      circle at 20px -10px,
      #fff 24px,
      transparent 26px
    );
  }
  .Contact::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-size: 40px;
    background-image: radial-gradient(
      circle at 20px -10px,
      transparent 24px,
      #fff 26px
    );
  }

  h1 {
    color: var.$primary-colour;
    font-size: 56px;

    @media (max-width: 400px) {
      font-size: 35px;
    }
  }
  p {
    transition: 0.6s;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;

    a {
      color: var.$primary-colour;
      content: attr(title);
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      input {
        width: 49%;
      }
    }
    input {
      font-size: 18px;
      padding: 11px;
      margin-bottom: 7px;
      border: 1px solid rgb(118, 118, 118);
    }
    textarea {
      font-size: 18px;
      padding: 11px;
      resize: none;
      height: 300px;
      border: 1px solid rgb(118, 118, 118);
    }

    .draw-border {
      @include mix.btn-border-drawing(
        var.$secondary-colour,
        orange,
        4px,
        bottom,
        right
      );
    }

    .btn {
      background: none;
      border: none;
      cursor: pointer;
      line-height: 1.5;
      padding: 15px 22px;
      letter-spacing: 2px;
      margin-top: 39px;
      font-size: 20px;
      font-weight: 600;
      color: var.$secondary-colour;
      width: 300px;

      @media (max-width: 400px) {
        width: 200px;
        font-size: 16px;
        align-self: center;
      }
    }
  }
  .socials {
    width: 50%;
    padding: 100px 30px;
    @include mix.flexCenter(column);
    justify-content: center;

    @media (max-width: 1100px) {
      width: 100vw;
      align-self: center;
      flex-direction: row;
      background-color: rgb(159, 159, 159);
      padding: 30px;
      margin-top: 20px;

      h2 {
        display: none;
      }

      img {
        margin: 0 !important;
      }
    }

    h2 {
      color: var.$primary-colour;
      font-size: 36px;
      margin: 0px 0 20px -60px;
      z-index: 10;
    }
    img {
      cursor: pointer;
      height: 50px;
      width: auto;
      z-index: 10;

      &:hover {
        transform: scale(1.06);
      }
    }

    .listening-image {
      transform: scaleX(-1);
      position: absolute;
      height: 500px;
      bottom: 0;
      right: 0;
      pointer-events: none;

      @media (max-width: 1100px) {
        display: none;
      }
    }
  }

  // ---------------- curved top border styling ------------------
  .custom-shape-divider-top-1649532422 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1649532422 svg {
    position: relative;
    display: block;
    width: calc(222% + 1.3px);
    height: 99px;
  }
}

// section background text
.contact-background-text {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  font-size: 244px;
  opacity: 0.05;
  font-weight: 900;
  color: var.$primary-colour;
}
