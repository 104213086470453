@use "../../../scss/mixins" as mix;

.EmailPrompt {
  @include mix.flexCenter(column);
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 20px;
  padding: 14px 5px 5px 5px;
  border: 2px solid rgb(49, 132, 49);
  height: 130px;
  width: 270px;
  background: rgb(60, 156, 60);
  animation: happy 4.5s linear forwards;

  @keyframes happy {
    from {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @media (max-width: 450px) {
    transform: scale(0.8);
  }

  P {
    margin-top: 6px;
    font-size: 19px;
  }
}
