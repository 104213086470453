@use "./scss/mixins" as mix;
@use "./scss/functions" as func;
@use "./scss/variables" as var;

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Poppins&display=swap");

:root {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(24, 24, 24);
  color: var.$text-colour;
}

.light {
  @include mix.theme(true);
}

ul {
  list-style-type: none;
}

input,
textarea {
  outline: none;
  border: none;
  transition: box-shadow 200ms ease-out;
  animation-duration: reverse;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: inset 0 -4px rgb(6, 206, 46) !important;
}

a {
  text-decoration: none;
}
