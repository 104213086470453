@use "./scss/mixins" as mix;

.App {
  @include mix.flexCenter(column);
  position: relative;
  main {
    width: 80%;
    @include mix.flexCenter(column);
  }
  overflow-x: hidden;
}
