@use "../../scss/variables" as var;
@use "../../scss/functions" as func;
@use "../../scss/mixins" as mix;

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

@font-face {
  src: url(../../fonts/umopMedium.ttf);
  font-family: "umop";
}

.Header {
  @include mix.flexCenter(column);
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  transition: 0.6s;

  @media (max-width: 900px) {
    header {
      transform: scale(0.7);
      transform-origin: left;
    }
    .typewriter {
      p {
        font-size: 25px !important;
      }
    }
    .header-button {
      transform: scale(0.9);
      transform-origin: left;
    }
  }

  @media (max-width: 570px) {
    header {
      transform: scale(0.5);
      h1 {
        transform: rotate(0) !important;
        margin: 0 !important;
      }
    }
    .typewriter {
      p {
        font-size: 22px !important;
      }
    }
    .header-button {
      transform: scale(0.8);
      transform-origin: left;
    }
  }

  @media (max-width: 420px) {
    header {
      transform: scale(0.4);
      h1 {
        transform: rotate(0) !important;
        margin: 0 !important;
      }
    }
  }

  @media (max-width: 335px) {
    header {
      transform: scale(0.3);
      h1 {
        transform: rotate(0) !important;
        margin: 0 !important;
      }
    }
    .typewriter {
      p {
        font-size: 17px !important;
      }
    }
    .header-button {
      transform: scale(0.7);
      transform-origin: left;
    }
  }

  header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    span {
      font-family: "umop";
      font-size: 39px;
      border: 1px solid orange;
      padding: 22px 15px;
      border-radius: 2px;
      background: orange;
      margin-right: 6px;
      opacity: 0;

      animation: logo ease-out 1s forwards;
      animation-delay: 0.5s;
      @keyframes logo {
        from {
          transform: translateX(-300%) scale(1);
          opacity: 0.3;
        }
        to {
          transform: translateX(0%) scale(1);
          opacity: 1;
        }
      }
    }

    h1 {
      font-size: 103px;
      font-family: "Josefin Sans", sans-serif;
      color: var.$primary-colour;
      white-space: nowrap;
      opacity: 0;
      margin-left: -24px;
      margin-top: -34px;
      cursor: grab;

      animation: full-name ease-in-out 2.2s forwards;
      animation-delay: 1s;
      @keyframes full-name {
        0% {
          transform: translateY(-500%);
          opacity: 0.8;
          z-index: 1000;
        }

        60% {
          z-index: 0;
        }

        70% {
          transform: rotate(8.5) translateY(-70%);
        }

        71% {
          transform: rotate(16.5deg);
          opacity: 1;
        }
        100% {
          transform: rotate(16.5deg) translateY(0%);
          opacity: 1;
        }
      }
      &:hover {
        color: orange;
      }
    }
  }

  .typewriter {
    p {
      font-size: 40px;
      font-weight: func.weight(medium);
      overflow: hidden;
      border-right: 0.15em solid orange;
      white-space: nowrap;
      margin: 0 auto;
      letter-spacing: 0.15em;
      opacity: 0;

      animation: typing 2.7s steps(34, end) forwards,
        blink-caret 1.5s step-end infinite;
      animation-delay: 2.4s;

      @keyframes typing {
        0% {
          width: 0%;
          opacity: 0;
        }
        100% {
          width: 100%;
          opacity: 1;
        }
      }
      @keyframes blink-caret {
        from,
        to {
          border-color: transparent;
        }
        50% {
          border-color: orange;
        }
      }
    }
  }

  .draw-border {
    @include mix.btn-border-drawing(
      var.$secondary-colour,
      orange,
      4px,
      bottom,
      right
    );
  }

  //=== Button styling
  .btn {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    padding: 15px 22px;
    letter-spacing: 2px;
    margin-top: 39px;
    opacity: 0;
    font-size: 20px;
    font-weight: 600;
    color: var.$secondary-colour;

    animation: button 1s forwards;
    animation-delay: 5.6s;

    @keyframes button {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

// class for when user dragges h1
.dragged {
  animation: fix-text ease-out 1s forwards !important;
  opacity: 0;
  @keyframes fix-text {
    from {
      transform: rotate(16.5deg);
    }
    to {
      opacity: 1;
      transform: rotate(0);
      margin-left: 0px;
      margin-top: 0px;
    }
  }

  &:hover {
    color: var.$primary-colour !important;
  }
}

/*--------- star/sun styling -----------------------------*/

@mixin translate50 {
  -webkit-transform: translate(-50, -50%);
  -ms-transform: translate(-50, -50%);
  -o-transform: translate(-50, -50%);
  transform: translate(50, 50%);
}

@mixin roundedCorners {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@mixin rotateBase {
  -webkit-transform: rotate3d(-1, 1, 0, 0deg);
  -ms-transform: rotate3d(-1, 1, 0, 0deg);
  -o-transform: rotate3d(-1, 1, 0, 0deg);
  transform: rotate3d(-1, 1, 0, 0deg);
}

@mixin rotateRight {
  -webkit-transform: rotate3d(-1, 1, 0, 30deg);
  -ms-transform: rotate3d(-1, 1, 0, 30deg);
  -o-transform: rotate3d(-1, 1, 0, 30deg);
  transform: rotate3d(-1, 1, 0, 30deg);
}

@mixin rotateLeft {
  -webkit-transform: rotate3d(-1, 1, 0, -30deg);
  -ms-transform: rotate3d(-1, 1, 0, -30deg);
  -o-transform: rotate3d(-1, 1, 0, -30deg);
  transform: rotate3d(-1, 1, 0, -30deg);
}

// n is number of stars generated
@function generateStars($n) {
  $value: "#{0} #{random(5000)}px #{random(5000)}px #FFF";

  @for $i from 2 through $n {
    $value: "#{$value} , #{random(5000)}px #{random(5000)}px #FFF";
  }

  @return unquote($value);
}

$stars-small: generateStars(1000);
$stars-medium: generateStars(400);
$stars-big: generateStars(200);

.stars-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-self: center;
  width: 200%;
  height: 100vh;

  @include translate50;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $stars-small;
  animation: animateStars 28.5s ease-in-out infinite;
  @include roundedCorners;

  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
    @include roundedCorners;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $stars-medium;
  animation: animateStars 30s ease-in-out infinite;
  @include roundedCorners;

  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
    @include roundedCorners;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $stars-big;
  animation: animateStars 31.5s ease-in-out infinite;
  @include roundedCorners;

  &:after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    @include roundedCorners;
  }
}

@keyframes animateStars {
  0% {
    @include rotateBase;
  }
  25% {
    @include rotateRight;
  }
  50% {
    @include rotateBase;
  }
  75% {
    @include rotateLeft;
  }
  100% {
    @include rotateBase;
  }
}

.the-sun {
  opacity: 1;
  box-shadow: 0px 0px 15px 3px yellow;
  width: 700px;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -200px;
  margin-right: -200px;
  border-radius: 50%;
  background-image: radial-gradient(circle, #ffd200 95%, #f7971e);

  animation: sunpulse 2s alternate infinite;
  @keyframes sunpulse {
    from {
      box-shadow: 0 0 1000px #ff0, 0 0 1000px #ff0;
    }
    to {
      box-shadow: 0 0 25px rgb(220, 220, 59), 0 0 75px #ff0;
    }
  }
}
