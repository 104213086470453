@use "../../scss/mixins" as mix;
@use "../../scss/variables" as var;

@font-face {
  src: url(../../fonts/umopMedium.ttf);
  font-family: "umop";
}

.Nav {
  @include mix.flexCenter(row);
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 55px 100px;
  z-index: 10;
  overflow: hidden;
  transition: 0.6s;

  @media (max-height: 850px) {
    padding: 25px 100px;
  }

  @media (max-height: 675px) {
    padding: 10px 100px;
  }

  @media (max-width: 600px) {
    padding: 10px 30px;
  }

  // logo
  h1 {
    font-family: "umop";
    @include mix.flexCenter(row);
    font-size: 25px;
    margin: 0;
    letter-spacing: 3px;
    cursor: pointer;
    @media (max-width: 420px) {
      margin-left: -23px;
    }
  }
  // list on the right of nav
  ul {
    @include mix.flexEnd(row);
    margin-right: 40px;
    li {
      transition: 1s;
      margin: 0 10px;
      cursor: pointer;
      font-size: 17px;

      @media (max-width: 700px) {
        display: none;
      }
      &:hover {
        color: var.$primary-colour;
      }
    }
    .change-theme {
      cursor: pointer;
      font-size: 30px;
      margin-left: 7px;
    }
  }
}

// styles for the react icons
.sun {
  @media (max-width: 700px) {
    display: none;
  }
  &:hover {
    border-radius: 50%;
    box-shadow: yellow 0px 0px 2px, yellow 0px 8px 24px, yellow 0px 1px 11px;
  }
}
.moon {
  @media (max-width: 700px) {
    display: none;
  }
  &:hover {
    border-radius: 50%;
    box-shadow: blue 0px 1px 0px, blue 0px 8px 24px, blue 0px 1px 11px;
  }
}

// styling for when user isn't at the top of the page
.sticky {
  padding: 5px 40px;
  ul {
    li {
      font-size: 13px;
      font-weight: map-get(var.$font-weights, light);
    }
  }
}

.nav-modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.646);
  z-index: 1000;

  @media (max-width: 700px) {
    display: initial;
  }

  .nav-modal-content {
    @include mix.flexCenter(column);
    height: 100%;
    width: 100%;
    position: absolute;
    background: black;
    color: var.$primary-colour;

    .close {
      filter: invert(100%);
      position: absolute;
      top: 0;
      right: 0;
      margin: 40px;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -250px;
      .sun,
      .moon {
        position: absolute;
        top: 0;
        left: 0;
        margin: 40px;
      }
    }
    li {
      margin: 16px 0;
      font-size: 30px;
    }
    .nav-links-container {
      position: absolute;
      bottom: 0;
      width: 100vw;
      @include mix.flexCenter(row);
      img {
        height: auto;
        width: 50px;
        margin: 5px 10px;
        cursor: pointer;
      }
      .github-small-nav {
        filter: invert(90%);
      }
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  margin-right: -63px;
  @media (max-width: 700px) {
    display: initial;
  }
}
