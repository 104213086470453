@use "../../../scss/mixins" as mix;

.ExtraProject {
  cursor: pointer;
  height: 230px;
  width: 280px;
  position: relative;

  margin: 2px;
  transition: 0.2s ease-in;

  &:hover {
    transform: scale(1.02);
  }

  .extra-project-background {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.15);
  }

  main {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include mix.flexCenter(column);
    justify-content: flex-start;
    padding: 12px 17px;

    h1 {
      font-size: 17px;
      width: 100%;
      margin-bottom: 10px;
    }
    p {
      color: white;
      font-size: 13px;
      width: 80%;
      align-self: flex-start;
    }

    .extra-technologies-container {
      position: absolute;
      color: orange;
      font-size: 12px;
      width: 100%;
      bottom: 20%;
      padding: 0 17px;
    }

    .extra-links-container {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 4px;
      *:hover {
        transform: scale(1.09);
      }
      a {
        background-color: none;
        img {
          height: 25px;
          width: 25px;
          filter: invert(100%);
          margin: 0 8px 0 17px;
        }
      }
    }
  }
  @media (max-width: 750px) {
    height: 250px;
    width: 250px;
  }
}
