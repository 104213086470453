@use "../../scss/mixins" as mix;
@use "../../scss/variables" as var;

.About {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 80%;
  transition: 0.6s;
  margin: 130px 0 200px;
  align-self: center;
  scroll-margin: 100px;
  z-index: 0;

  @media (max-width: 1200px) {
    width: 100%;
  }

  h1 {
    font-size: 56px;
    color: var.$primary-colour;
    margin-bottom: 50px;
    @media (max-width: 400px) {
      font-size: 35px;
    }
  }

  .About-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .About-top-scroll {
      width: 60%;
      align-self: center;
      margin-bottom: 40px;
      padding: 0 50px;
      @media (max-width: 1700px) {
        width: 80%;
      }
      @media (max-width: 950px) {
        width: 100%;
      }
    }

    .About-top {
      width: 100%;
      margin-bottom: 40px;
      padding: 0 50px;

      @media (max-width: 650px) {
        padding: 0;
        align-self: center;
        width: 90vw;
        margin-left: -66px;
        margin-bottom: 0px;
      }
      p {
        font-size: 19 px;
      }
      .about-me-technologies-p {
        color: var.$primary-colour;
        font-weight: 600;
        font-size: 22px;
      }
    }
    .About-technologies {
      @include mix.flexCenter(column);
      width: 900px;
      align-self: center;
      padding: 30px;
      @media (max-width: 950px) {
        flex-direction: row;
      }

      .About-frontend {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        margin-left: -230px;
        @media (max-width: 950px) {
          margin: 0 20px;
        }

        h3 {
          position: relative;
          font-size: 27px;
        }
        h3:after {
          background-color: var.$primary-colour;
          content: "";
          display: inline-block;
          height: 3px;
          position: relative;
          vertical-align: middle;
          width: 76%;
          @media (max-width: 950px) {
            display: none;
          }
        }

        h3:after {
          left: 0.5em;
          margin-right: -50%;
        }
      }
      .About-backend {
        @extend .About-frontend;
        align-self: flex-end;
        margin-right: -50px;

        @media (max-width: 950px) {
          margin: 0;
          align-self: flex-start;
          display: grid;
          margin: 0 20px;
        }
        h3 {
          width: 100%;
          text-align: end;
        }
        h3:before {
          background-color: var.$primary-colour;
          content: "";
          display: inline-block;
          height: 3px;
          position: relative;
          vertical-align: middle;
          width: 95%;
          @media (max-width: 950px) {
            display: none;
          }
        }

        h3:before {
          right: 0.5em;
          margin-left: -50%;
        }
        h3:after {
          display: none;
        }
      }
      ul {
        display: flex;
        padding: 40px 0 30px;

        li {
          margin: 0 30px;
          @include mix.flexCenter(column);
          justify-content: flex-end;
          width: 52px;
          height: 52px;

          @media (max-width: 950px) {
            flex-direction: row;
            margin: 0;
            padding: 0;
          }
          img {
            object-fit: scale-down;
            height: 44px;
            width: 44px;
          }
          h4 {
            margin-top: 7px;
            font-size: 17px;
          }
        }
        @media (max-width: 950px) {
          flex-direction: column;
          margin: 0;
          padding: 0;
          img {
            display: none;
          }
          li {
            color: var.$primary-colour;
            width: auto;
            height: auto;
            align-self: center;
          }
        }
      }
    }
  }
  // section background text
  .about-background-text {
    position: absolute;
    top: 0;
    right: 0;
    color: var.$primary-colour;
    width: 400px;
    z-index: -1;
    font-size: 244px;
    opacity: 0.05;
    font-weight: 900;
    margin-top: -100px;
  }
}
